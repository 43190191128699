import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { TABLET } from '../../constants/screens';
import withReduxFormError from '../../enhancers/withReduxFormError';
import { getComponentByType } from '../../utils';

const Interests = ({ fields }) => (
  <ContentContainer>
    <FieldsBox>
      <FieldsContainer>
        {fields.map(({
          id, label, type, htmlName, selectOptions,
        }) => (
          <Field
            key={id}
            name={htmlName}
            options={selectOptions}
            component={getComponentByType(type)}
            props={{ markdownText: label.md }}
          />
        ))}
      </FieldsContainer>
    </FieldsBox>
  </ContentContainer>
);

Interests.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const FieldsContainer = styled.div`
  columns: 2;
  
  @media screen and (max-width: ${TABLET}) {
    columns: 1;
  }
  
  & > label {
    margin-bottom: 20px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
`;

const FieldsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export default withReduxFormError()(Interests);
