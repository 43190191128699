import React from 'react';
import Markdown from 'react-markdown';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { BLUE, DARK_GRAY_2 } from '../../constants/colors';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const TextMarkdown = ({ ...props }) => (
  <Text {...props} />
);

TextMarkdown.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default TextMarkdown;

const text = css`
  font-family: AvenirNextLTPro;
  font-size: ${props => props.size};
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${DARK_GRAY_2};
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: ${props => (props.size === '21px' ? '18px' : '16px')};
  }
  
  @media screen and (max-width: ${TABLET}) {
    font-size: 17px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
     font-size: 17px;
  }
`;

const Text = styled(Markdown)`
  ${text};
  margin-top: 30px;
  text-align: justify;
  
  a {
    color: ${BLUE};
    text-decoration: none;
  }
`;
